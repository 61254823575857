import '../App.css';
import { useRouteError } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Container fluid>
        <Row className='error-page text-center align-items-center'>
            <Col>
            <div>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
            </Col>
        </Row>
    </Container>
  );
}