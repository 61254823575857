import { useState } from 'react';

import { useAppContext } from './AppContext';

import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { FileLock2Fill } from 'react-bootstrap-icons';
import { Navigate, Link } from "react-router-dom";

export default function LoginForm() {
    const { login, setAlert } : any = useAppContext();
    const [ result, setResultMessage] : any = useState("");
    const [ triesRemaining, setTriesRemaining] : any = useState(3);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if( triesRemaining <= 0) return;
        const data = new FormData(event.currentTarget);

        console.log('Submitting Login Form');
        try {
            // login({ 
            //     username: data.get("username"), 
            //     password: data.get("password")
            // })
            // .then( () => {
            //     setAlert('You have successfully logged into the application!', 'success');
            // })
            // .catch( (e:any) => { 
            //     setTriesRemaining( triesRemaining - 1 );    
            //     setResultMessage('Invalid username or password');
            // });            
            setTriesRemaining( triesRemaining - 1 );    
            setResultMessage('Invalid username or password');
        } catch(e) {
            setTriesRemaining( triesRemaining - 1 );
        }
    };
        
    return (
        <Container>
        <Row className='sparse-page text-center align-items-center justify-content-md-center'>
            <Col md="auto">
                <FileLock2Fill color="black" size={48} />                
                <Form onSubmit={handleSubmit} noValidate>
                    <fieldset>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" name="username"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password"/>
                        </Form.Group>         
                        <Button variant="secondary" type="submit" className="me-2" disabled={triesRemaining <= 0}>
                            Login
                        </Button>
                    </fieldset>
                </Form>
            </Col>
        </Row>
        <Row className="justify-content-md-center">
            <Col md="auto">
                <div hidden={!result}>
                    <span className="error">{result}!</span> Please try again. You have <span className="error">{triesRemaining}</span> tries remaining.
                    <br/>
                    <div className="text-center mt-2">Did you <Link to="/reset" className="loginLink">forget your password?</Link></div>
                </div>
            </Col>
        </Row>
        <Row className="text-center align-items-center mt-5">
            <Col>
            Don't have an account?  <Link to="/register" className="loginLink">Register here</Link>
            </Col>
        </Row>
        </Container>
    );
  }