import { useEffect, useState, useRef } from "react";
import { Col, Container, Row, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useAppContext } from "./AppContext";
import { useLocalStorage } from "./localStorage";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import { useAPI, isEqual } from "../AppUtils";
import { useParams, useNavigate } from "react-router-dom";

//#region INTERFACES
interface LibraryRow {
  identity: string;
  type: string;
  state: string;
  token: string;
  selected: boolean;
}
interface LibraryList {
  data: Array<LibraryRow>;
  selected: Array<string>;
}

interface LibraryData {
  id: string;
  status: string;
  type: string;
  token: string;
}

//#endregion INTERFACES

export const GameLibrary = () => {
  const defaultStateData: LibraryList = {data:[],selected:[]};

  //#region REACT HOOKS
  const { tab } = useParams();
  const nav = useNavigate();
  const { post } = useAPI();
  const { subscribe, unsubscribe, setAlert }: any = useAppContext();
  // const [ auth, setAwsAuth ] = useLocalStorage("awsauth", defaultAwsAuth);
  //#endregion

  //#region STATE
  const [ident, setLibrary] = useState<LibraryList>(defaultStateData);
  const [showAdd, setShow] = useState(false);  
  //#endregion STATE

  //#region INITIALIZATION 
  const myState: any = useRef(ident);

  const mySetData = (v:any) => {
    myState.current = v;
    setLibrary(v);
  }

  const escFunction = (event:any) => {
    if(event.key === "Escape") {
      let newData = myState.current.data.map((item:any) => {
        item.selected = false;
        return item;
      });
      mySetData({data: newData, selected: []});
    }
  }

  useEffect(() => {
    // Trap the ESC key for convenient table selection interactions
    document.addEventListener("keydown", escFunction);

    // Hack for injecting state into global keypress event handler 
    myState.current = ident;

    // Setup WebSocket message handling
    console.log('Subscribing to WebSocket events');
    subscribe('MessageReceived', messageHandler);

    // Tab/Route adjustment based on current context
    // if(!tab) {
    //   if(auth && auth.id && auth.key) nav('list');
    //   else nav('settings');
    // }

    // Load the first page of data and then let the server lazy-load the rest of the 
    // data using the websocket messaging channel if user credentials are available:
    // if(auth && auth.id && auth.key) {
    //   handleRefresh(null);
    //   handleStatsRefresh(null);
    // }

    // Component Cleanup
    return () => {
      document.removeEventListener("keydown", escFunction);

      console.log('Unsubscribing from WebSocket events');
      unsubscribe('MessageReceived', messageHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion INITIALIZATION

  //#region UTILITY FUNCTIONS

  const processLibraryData = (data: Array<LibraryData>) => {
    // NOTE: when this method is called from the SignalR
    // messaging callback it will have no context and so
    // we need to use the same reference hack we used for the
    // global key handler here
    let newData: Array<LibraryRow> = myState.current.data;
    data.forEach((item: LibraryData) => {
      let newRow: LibraryRow = {identity: item.id, type: item.type, state: item.status, token: item.token, selected: false };  
      // HULK-SMASH!!!
      // The SES API is so slow that it is well within reason for an impatient user
      // to request overlapping refreshes, so to avoid a state data collision issue
      // we check every new record against the existing state and update/add as needed
      const index = newData.findIndex((r:LibraryRow) => r.identity === newRow.identity);
      if (index === -1) {
        newData.push(newRow);
      } else {
        newData[index] = newRow;
      }      
    });
    let newState: any = { data: newData, selected: [] };
    mySetData(newState);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //#endregion UTILITY FUNCTIONS

  //#region EVENT HANDLERS

  const messageHandler = (event: any) => {
    let msg = JSON.stringify(event.detail);
    console.log('AWS SES Data: ' + msg);
    // Super lazy interface design on the back-end messaging
    // interface uses string, so we need to parse the result
    processLibraryData(JSON.parse(event.detail));
  };

  // const handleAuthSave = (event: any) => {
  //   setAwsAuth(formAuth);  
  // }

  const handleAuthClear = (event: any) => {
    // if(window.confirm("Are you sure you want to remove these credentials?")) {
    //   setFormAuth(defaultAwsAuth);    
    //   setAwsAuth(defaultAwsAuth);
    // }
  };

  const handleInputChange = (event: any) => {
    // setFormAuth({
    //   ...formAuth,
    //   [event.target.name]: event.target.value
    // });
  }

  // const handleMailSubmit = async (event: any) => {
  //   event.preventDefault();
  //   if( mail.from && mail.to && mail.subject) {
  //     if(window.confirm("Are you sure you want to send the configured email?")) {
  //       let request = {...auth, ...mail};
  //       post('/api/Mail/SendMail', request)
  //       .then((response:string) => {
  //         console.log('Mail was successfully sent with Id=' + response);
  //         setAlert('The configured email was successfully sent with MessageId ' + response, 'success', 0);
  //       })
  //       .catch((error:any) => {
  //         console.error('Error encountered during network op: ', error);
  //         setAlert('The mail could not be sent due to an error returned by AWS: ' + error, 'error', 0);
  //       });  
  //     }  
  //   } else {
  //     setAlert("Email cannot be sent as configured due to missing information.  The From and To addresses must be configured.  A subject must be provided for the message.","error", 0);
  //   }
  // };

  const handleTableClick = (event: any, row: LibraryRow) => {
    //let rowIndex = event.currentTarget.rowIndex;    
    //console.log('Got Click from table: Row=' + rowIndex + ",Id=" + row.identity);
    let newSelected: Array<string> = [];
    const newData = ident.data.map((item) => {
      let result = item;
      if( item.identity === row.identity) {
        row.selected = row.selected ? false : true;
        result = row;
      } 
      if(item.selected) {
        newSelected.push(result.identity);
      }      
      return result;
    });
    mySetData({ data: newData, selected: newSelected});
  };

  const handleRefresh = async (event: any) => {
    mySetData(defaultStateData);
    // let request: any = {...auth};
    // post('/api/Mail/GetIdentities', request)
    // .then((data:Array<IdentityData>) => {      
    //   console.log('Identities retrieved: ' + JSON.stringify(data));
    //   processIdentityData(data);
    // })
    // .catch((error:any) => {
    //   console.error('Error encountered during network op: ', error);
    // });
  }

  const handleRemove = (event: any) => {
    // if(window.confirm("Are you sure you want to remove the selected identities?")) {
    //   console.log('Remove selected records here: ' + JSON.stringify(ident.selected));
    //   let request: any = {...auth, ids: ident.selected};
    //   post('/api/Mail/RemoveIdentity', request)
    //   .then((data:string) => {        
    //     let newData: Array<IdentityRow> = ident.data.filter( ( item ) =>  !ident.selected.includes( item.identity ));
    //     mySetData({ data: newData, selected: [] });
    //   })
    //   .catch((error:any) => {
    //     console.error('Error encountered during network op: ', error);
    //   });        
    // }
  }

  const handleAdd = (event: any) => {
    event.preventDefault();
    setShow(false);    

    // const formData = new FormData(event.currentTarget);
    // let newIdentity: any = formData.get("identity");
    // let request: any = {...auth, identity: newIdentity};
    // post('/api/Mail/CreateIdentity', request)
    // .then((response:Array<IdentityData>) => {
    //   console.log('CreateIdentity return: ' + JSON.stringify(response));
    //   processIdentityData(response);
    // })
    // .catch((error: any) => {
    //   console.error('Error encountered during network op: ', error);
    // });
  }

  //#endregion EVENT HANDLERS

  //#region RENDERING

  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={12} md={8} lg={4}>
            Library Content Here
          </Col>
        </Row>         
      </Container>        
      <Modal show={showAdd} onHide={handleClose}>
        <Form onSubmit={handleAdd} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>Add Game</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group>
                <Form.Label>Game Name</Form.Label>
                <Form.Control type="text" name="game" placeholder="Enter Game Name" autoFocus/>
              </Form.Group>    
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} className="me-2">Close</Button>
            <Button variant="primary" type="submit">Save</Button>
          </Modal.Footer>
        </Form>        
      </Modal>
    </div>
  );
  //#endregion
}