import { Navigate, Link, useOutlet } from "react-router-dom";

// BOOTSTRAP IMPORTS
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useAppContext } from './AppContext';

export const OpenLayout = () => {
    const { user }: any = useAppContext();
    const outlet = useOutlet();

    if (user) {
        return <Navigate to="/home" />;
    }    

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top" className="mb-5">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/">My Game Library</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/login">Login</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {outlet}
        </div>
    );
}
