import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { API_BASE_URL, ALERT_TIME } from "../Constants";
import { useLocalStorage } from "./localStorage";
import useAPI from "../AppUtils";

const AppContext = createContext({});

export const AppProvider = ({children} : any) => {
  const [user, setUser] = useLocalStorage("user", null);  
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [type, setType] = useState('');
  const [ connection, setConnection ] : any = useState(null);
  const { post } = useAPI();

  useEffect(() => {
    if(user && user.token) {
      const newConnection = new HubConnectionBuilder()
      .withUrl(API_BASE_URL + '/hubs/client', {
        accessTokenFactory: () => user.token
      })
      .withAutomaticReconnect()
      .build();
      setConnection(newConnection);
    } else {
      console.log('No user token is available.');
      if(connection) {
        connection.stop()
        .then((result: any) => {
          console.log('WebSocket connection has been terminated');
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (connection) {
        connection.start()
            .then( (result: any) => {
                console.log('Connected to App Hub!');

                connection.on('ReceiveMessage', (message: any) => {
                  //console.log('Received WebSocket Message from server: ' + message.length);
                  publish('MessageReceived', message);
                });
            })
            .catch((e: any) => console.log('App Hub Connection failed: ', e));
    }
  }, [connection]);

  const sendMessage = async (data: any) => {
    if (connection) {
        try {
            await connection.invoke('ReceiveMessage', JSON.stringify(data));
        } catch(e) {
            console.log(e);
        }
    } else {
        console.log('No connection to server!');
    }
  }  

  const subscribe = (eventName: any, listener: any) => {
    document.addEventListener(eventName, listener);
  }
  
  const unsubscribe = (eventName: any, listener: any) => {
    document.removeEventListener(eventName, listener);
  }
  
  const publish = (eventName: any, data: any) => {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
  }

  // call this function to authenticate the user
  const login = async (content: any) => {    
    return post('/api/Token', content)
    .then((token:string) => {
      console.log(token);
      setUser({
        username: content.username,
        token: token
      });
      navigate("/home");      
    })
    .catch((error:any) => {
      console.error('Error encountered during network op: ', error);
      return Promise.reject(error);
    });
  };

  // call this function to de-authenticate the user
  const logout = () => {
    setUser(null);
    setAlert('Logged out', 'success');
    navigate("/", { replace: true });
  };

  // call this function to access the app-global message notifier
  const setAlert = (text: string, type: string, timeout: number = -1) => {
    setText(text);
    setType(type);
    if( timeout !== 0 ) {
      let alertTime: number = ALERT_TIME;
      if( timeout > 0 && timeout > ALERT_TIME) {
        alertTime = timeout;
      }
      setTimeout(() => {
        setText('');
        setType('');
      }, alertTime);
    }
  };
  
  const value = useMemo( () => ({
      user,
      connection,
      text,
      type,
      login,
      logout,
      setAlert,
      sendMessage,
      subscribe,
      unsubscribe
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, connection, text, type]
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return useContext(AppContext);
};