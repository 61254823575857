import { createBrowserRouter } from "react-router-dom";
import './App.css';
import { AppLayout } from './components/AppLayout';
import { OpenLayout } from "./components/OpenLayout";
import { ProtectedLayout } from './components/ProtectedLayout';
import ErrorPage from "./components/ErrorPage";
import LoginForm from './components/Login';
import { Welcome, WelcomeAuthorized } from './components/Home';
import { GameLibrary } from "./components/GameLibrary";
import Register from "./components/Register";
import PasswordReset from "./components/PasswordReset";

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <OpenLayout />,          
        children: [
          { index: true, element: <Welcome /> },
          { path: "/", element: <Welcome /> },
          { path: "/login", element: <LoginForm />},
          { path: "/register", element: <Register />},
          { path: "/reset", element: <PasswordReset />},
        ]
      },
      {
        path: "/home",
        element: <ProtectedLayout />,
        children: [
          { index: true, element: <WelcomeAuthorized />},
          {
            path: "library",
            element: <GameLibrary />,
            // children: [
            //   { path: ":tab", element: <Library />} 
            // ]
          },
          // Add Additional Authenticated Routes Below Here
        ]
      }
    ]
  }
]);
