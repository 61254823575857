import { Navigate, Link, useOutlet } from "react-router-dom";
import { BoxArrowRight } from 'react-bootstrap-icons';

// BOOTSTRAP IMPORTS
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { useAppContext } from "./AppContext";
import { API_BASE_URL } from "../Constants";

export const ProtectedLayout = () => {
  const { user, logout }: any = useAppContext();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
          <Container fluid>
              <Navbar.Brand as={Link} to="/">WPM AWS TEST</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <a className="nav-link" href={API_BASE_URL + "/swagger"} target="_api">API Docs</a>
                  <Nav.Link as={Link} to="/home/ses">Simple Email Service</Nav.Link>
                </Nav>
              <Nav>
                <Navbar.Text>
                  <span id="userWelcome">Welcome {user.username}!</span>
                  <Link to="/" onClick={logout}><BoxArrowRight color="royalblue" size={25} title="Logout" /></Link>
                </Navbar.Text>                                       
              </Nav>
              </Navbar.Collapse>
          </Container>
      </Navbar>
      {outlet}
    </div>
  );
};