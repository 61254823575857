import { useAppContext } from "./components/AppContext";
import { API_BASE_URL } from "./Constants";

export const isEqual = (a: any, b: any) => {
    const ka = Object.keys(a);
    const kb = Object.keys(b);
    if (ka.length !== kb.length) { return false; }
    for (let key of ka) {
      if (a[key] !== b[key]) { return false; }
    }
    return true;
}

export const useAPI = () => {
    const { user, logout }: any = useAppContext();

    const getHeaders = () => {
        let headers : any = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        };
    
        if(user && user.token){
          headers["Authorization"] = "Bearer " + user.token;
        }
        return headers;
      }
    
    const get = (url: string) => {    
        let options = {
            method: 'GET',
            headers: getHeaders()
        };
        return fetch(API_BASE_URL + url, options).then(handleResponse);
    }
    
    const post = (url: string, content: any) => {
        let options = {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(content)
        };
        return fetch(API_BASE_URL + url, options).then(handleResponse);
    }
    
    const handleResponse = (response: any) => {
        return response.text().then( (text: any) => {
            const data = text && JSON.parse(text);        
            if (!response.ok) {
                if(response.status === 401 && user && user.token) {
                    // This is a simple hack to detect token expiration without having to track
                    // the actual JWT token parameters.  It is possible for the server
                    // to respond with a 401 without it being a token expiration issue, but
                    // it is easier to just kick-start the entire user auth process
                    logout();
                }
                const error = (data && data.message) || data || (response.statusText || response.status);
                return Promise.reject(error);
            }
            return data;
        });
    }
    
    return {
        get,
        post
    }
}

export default useAPI;