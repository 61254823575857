import { Link } from "react-router-dom";
import { useAppContext } from "./AppContext";
import '../App.css';
import { Col, Container, Row } from 'react-bootstrap';

export const Welcome = () => {
  return (
    <Container fluid>
      <Row className='text-center align-items-center'>
        <Col>
          <h1>Welcome to My Game Library!</h1>
        </Col>
      </Row>
      <Row className='text-center align-items-center'>
        <Col>
          <ul className="homeList">
            <li>Tired of buying the same game on multiple platforms by accident?</li>
            <li>Tired of buying a game only to find out a week later that it is getting released for free under one of your gaming subscriptions?</li>
            <li>Tired of not knowing for which platform your friends have purchased a multplayer game?</li>
            <li>Do you want to track your gaming portfolio across the entire known gaming universe?</li>              
          </ul>
          If you answered <strong>yes</strong> to any of the questions above, then breathe a sigh of relief, because
          <h2 className="mt-5">COMING SOON</h2>
          <div className="homeBottom">
            <h3 className="mt-5">My Game Library</h3>
            A private, secure, and interactive games library that will enable you to do the following:
            <ul className="homeList">
              <li>Track your gaming portfolio across all platforms</li>
              <li>Search the known gaming universe to find on which platform(s) a game may be available</li>
              <li>Monitor your pre-orders and be notified if any of them get added to a gaming subscription service</li>
              <li>Track your friends' game purchases and the platform(s) on which they purchased it (if they let you, that is!)</li>
              <li>...and more!</li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className='text-center align-items-center'>
        <Col>
          <div className="underConstruction">UNDER CONSTRUCTION</div>
        </Col>
      </Row>
    </Container>
  );
}

export const WelcomeAuthorized = ( ) => {
  const { user } : any = useAppContext();
  return (
    <Container fluid>
      <Row className='sparse-page text-center align-items-center'>
        <Col>
        <div>
            <h1>Welcome {user.username}!</h1>
            <p>
            Please select a test module from the available options above.
            </p>
        </div>
        </Col>
      </Row>
    </Container>
  );
};
