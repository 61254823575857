import { useState, useEffect } from 'react';
import { useOutlet } from "react-router-dom";
import { AppProvider } from "./AppContext";
import { useAppContext } from './AppContext';
import Offcanvas from 'react-bootstrap/Offcanvas';

export const AlertSlider = (props: any) => {
  const [show, setShow] = useState(true);
  const { text, type, setAlert} : any = useAppContext();
  
  useEffect(() => {
    if(text && text.length > 0) {
      handleShow();
    }
  }, [text,type]);

  const handleClose = () => {    
    setShow(false);
    setAlert('','', 0);
  };

  const handleShow = () => setShow(true);
  
  if( text && type ) {
      return (
          <Offcanvas show={show} onHide={handleClose} {...props}>
              <Offcanvas.Header className={type === 'success' ? 'bg-success' : 'bg-danger'} closeButton>
                <Offcanvas.Title>{type === 'success' ? 'SUCCESS' : 'ERROR'}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {text}
              </Offcanvas.Body>
          </Offcanvas>
      );
  } else {
      return <></>;
  }
}

export const AppLayout = () => {
  const outlet = useOutlet();

  useEffect(() => {
    document.title = 'My Game Library';
  }, []);

  return (
    <AppProvider>
      <AlertSlider key="AppAlert" placement="end" />
      {outlet}
    </AppProvider>
  );
};