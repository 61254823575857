import { useState } from 'react';

import { useAppContext } from './AppContext';

import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { FileLock2Fill } from 'react-bootstrap-icons';
import { Navigate, Link } from "react-router-dom";

export default function Register() {
    const { login, setAlert } : any = useAppContext();

    return (
        <Container>
        <Row className='sparse-page text-center align-items-center justify-content-md-center'>
            <Col md="auto">
                We're sorry, but Account Registration is currently UNDER CONSTRUCTION
            </Col>
        </Row>
        </Container>
    );
}